import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import HeroOne from "../components/hero/HeroOne";
import ServiceOne from '../components/services/ServiceOne';
import AboutFour from '../components/about/AboutFour';

import FeatureFour from "../components/features/FeatureFour";
import FaqOne from "../components/faq/FaqOne";
import CounterTwo from '../components/counter/CounterTwo';
import CtaTwo from '../components/cta/CtaTwo';
import WorkPerformanceOne from "../components/work-performance/WorkPerformanceOne";
import BrandOne from "../components/brand/BrandOne";
import AboutTwo from '../components/about/AboutTwo';
import FooterOne from "../common/footer/FooterOne";

const HomeDefault = () => {
    return (
        <>
            <HeaderOne />
            <HeroOne />
            <AboutFour />
            <ServiceOne />
            <BrandOne />
            <FeatureFour />
            

            <FaqOne />
            <CounterTwo />
            <CtaTwo />
            <WorkPerformanceOne />
            <AboutTwo />
            <FooterOne />
        </>
    )
}

export default HomeDefault;