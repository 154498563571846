import React from 'react';

export default class FaqOne extends React.Component {
    componentDidMount() {

        const $ = window.$;
        
        if ($('.accordion-box').length) {
            $(".accordion-box").on('click', '.acc-btn', function () {
        
              var outerBox = $(this).parents('.accordion-box');
              var target = $(this).parents('.accordion');
        
              if ($(this).hasClass('active') !== true) {
                $(outerBox).find('.accordion .acc-btn').removeClass('active');
              }
        
              if ($(this).next('.acc-content').is(':visible')) {
                return false;
              } else {
                $(this).addClass('active');
                $(outerBox).children('.accordion').removeClass('active-block');
                $(outerBox).find('.accordion').children('.acc-content').slideUp(300);
                target.addClass('active-block');
                $(this).next('.acc-content').slideDown(300);
              }
            });
          }
          
    }
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="faq-one" id="faq">
                    <div className="container">
                        <div className="row">
                            {/* Start Faq One Img */}
                            <div className="col-xl-6">
                                <div className="faq-one__img wow slideInLeft" data-wow-delay="100ms" data-wow-duration="2500ms">
                                    <img src={publicUrl+"assets/images/update1.0/faq-v1-img1.jpg"} alt="#" />
                                </div>
                            </div>
                            {/* End Faq One Img */}

                            {/* Start Faq One Accordion */}
                            <div className="col-xl-6">
                                <div className="faq-one__accordion">
                                    <div className="sec-title">
                                        <div className="sec-title__tagline">
                                            <h6>Frequently Asked Questions</h6> <span className="right"></span>
                                        </div>
                                        <h2 className="sec-title__title">Have Any Questions</h2>
                                    </div>
                                    <ul className="accordion-box">
                                        <li className="accordion block">
                                            <div className="acc-btn">
                                                <div className="icon-outer">
                                                    <i className="icon-plus-sign"></i>
                                                </div>
                                                <h3>
                                                    1. How can I get in touch with your company for further inquiries or to initiate a construction project?
                                                </h3>
                                            </div>
                                            <div className="acc-content">
                                                <div className="inner">
                                                    <p>You can reach us by phone, email, or through our website's contact form. Our team is available to answer any questions, discuss your project requirements, and guide you through the construction process.</p>
                                                </div>
                                            </div>
                                        </li>

                                        <li className="accordion block active-block">
                                            <div className="acc-btn active">
                                                <div className="icon-outer">
                                                    <i className="icon-plus-sign"></i>
                                                </div>
                                                <h3>
                                                    2. How do I get an estimate for my construction project?
                                                </h3>
                                            </div>
                                            <div className="acc-content current">
                                                <div className="inner">
                                                    <p>You can contact us to schedule a consultation. Our team will evaluate your project requirements, conduct a site visit if necessary, and provide you with a detailed estimate that includes the scope of work, materials, labor costs, and any other relevant expenses.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>

                                        <li className="accordion block mb0">
                                            <div className="acc-btn">
                                                <div className="icon-outer">
                                                    <i className="icon-plus-sign"></i>
                                                </div>
                                                <h3>
                                                    3. How do you handle changes or modifications during a construction project?
                                                </h3>
                                            </div>
                                            <div className="acc-content">
                                                <div className="inner">
                                                    <p>We understand that changes may arise during construction. We maintain open communication with clients and provide a change order process to evaluate and implement requested modifications. We assess the impact on the project timeline and budget and keep the client informed throughout the process.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* End Faq One Accordion */}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}