import React from 'react';

export default class HeroOne extends React.Component {
    componentDidMount() {

        const $ = window.$;
        
        if ($('.slider-carousel').length) {
            $('.slider-carousel').owlCarousel({
              animateOut: 'fadeOut',
              animateIn: 'fadeIn',
              loop: true,
              margin: 0,
              nav: false,
              singleItem: true,
              smartSpeed: 500,
              autoplay: true,
              autoplayTimeout: 6000,
              navText: ['<span class="icon-right-arrow"></span>', '<span class="icon-right-arrow"></span>'],
              responsive: {
                0: {
                  items: 1
                },
                600: {
                  items: 1
                },
                1024: {
                  items: 1
                }
              }
            });
          }
          
    }
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="slider-one-sec style3">
                    <div className="slider-carousel owl-carousel owl-theme">
                        {/* Start Slider One Single */}
                        <div className="slider-one__single">
                            <div className="image-layer" style={{backgroundImage: 'url('+publicUrl+'assets/images/update1.0/slider-v1-img4.jpg)'}}>
                            </div>
                            <div className="container">
                                <div className="row clearfix">
                                    <div className="col-xl-12">
                                        <div className="slider-one__single-content">
                                            <h3>Building Dreams, Creating Realities</h3>
                                            <h2>Building with Integrity, Exceeding <br /> Expectations</h2>
                                            <p>Building dreams into reality through collaborative partnerships, superior craftsmanship,<br /> and an unwavering commitment to client satisfaction.</p>
                                            {/**
                                             <div className="btn-box">
                                                <Link to={process.env.PUBLIC_URL + `/`} className="thm-btn" data-text="Read More +">Read More +</Link>
                                            </div>
                                             */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Slider One Single */}

                        {/* Start Slider One Single */}
                        <div className="slider-one__single">
                            <div className="image-layer" style={{backgroundImage: 'url('+publicUrl+'assets/images/update1.0/slider-v1-img5.jpg)'}}>
                            </div>
                            <div className="container pl-0">
                                <div className="row clearfix">
                                    <div className="col-xl-12">
                                        <div className="slider-one__single-content text-center">
                                            <h3>Building a Better Future</h3>
                                            <h2>A Modern Construction & <br /> Industrial </h2>
                                            <p>Experienced construction company dedicated to excellence, craftsmanship, and<br /> exceeding client expectations in every project we undertake.</p>
                                            {/* 
                                            <div className="btn-box">
                                                <Link to={process.env.PUBLIC_URL + `/`} className="thm-btn" data-text="Read More +">Read More +</Link>
                                            </div>
                                            */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Slider One Single */}

                        {/* Start Slider One Single */}
                        <div className="slider-one__single">
                            <div className="image-layer" style={{backgroundImage: 'url('+publicUrl+'assets/images/update1.0/slider-v1-img6.jpg)'}}>
                            </div>
                            <div className="container">
                                <div className="row clearfix">
                                    <div className="col-xl-12">
                                        <div className="slider-one__single-content">
                                            <h3>Building Trust Through Quality</h3>
                                            <h2>Transforming Ideas, Constructing <br />Remarkable Environments</h2>
                                            <p>Delivering construction solutions that combine innovation, efficiency, and reliability,<br /> leaving a mark of excellence in every project.</p>
                                            {/*
                                            <div className="btn-box">
                                                <Link to={process.env.PUBLIC_URL + `/`} className="thm-btn" data-text="Read More +">Read More +</Link>
                                            </div>
                                            */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Slider One Single */}
                    </div>
                </section>
            </>
        )
    }
}