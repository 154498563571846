import React from 'react';

export default class FeatureFour extends React.Component {
    render(){
        return (
            <>
                {/* Start Feature Four */}
                <section className="feature-four" id="maintenance">
                    <div className="container">
                        <div className="sec-title text-center">
                            <div className="sec-title__tagline">
                                <span className="left"></span>
                                <h6>Maintenance</h6> <span className="right"></span>
                            </div>
                            <h2 className="sec-title__title">Our Maintenance Services</h2>
                        </div>
                        <div className="row">

                            {/* Start Feature Four Single */}
                            <div className="col-xl-4">
                                <div className="feature-four__single style2 text-center">
                                    <div className="feature-four__single-icon">
                                        <span className="icon-interior-design-1"></span>
                                    </div>
                                    <div className="feature-four__single-content">
                                        <span>EXPLORE THE SERVICES</span>
                                        <h2>Civil Infrastructure Maintenance</h2>
                                        <p>Providing maintenance and repair services for civil infrastructure such as roads, sidewalks, drainage systems, and parking lots, ensuring their functionality, safety, and longevity.</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Feature Four Single */}

                            {/* Start Feature Four Single */}
                            <div className="col-xl-4">
                                <div className="feature-four__single style2 text-center">
                                    <div className="feature-four__single-icon">
                                        <span className="icon-interior-design-2"></span>
                                    </div>
                                    <div className="feature-four__single-content">
                                        <span>EXPLORE THE SERVICES</span>
                                        <h2>Structural Repairs</h2>
                                        <p>Assessing and addressing structural issues such as cracks, settlement, and deterioration, and implementing repairs and reinforcements to ensure the safety and integrity of buildings and structures.</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Feature Four Single */}

                            {/* Start Feature Four Single */}
                            <div className="col-xl-4">
                                <div className="feature-four__single style2 text-center">
                                    <div className="feature-four__single-icon">
                                        <span className="icon-interior-design-1"></span>
                                    </div>
                                    <div className="feature-four__single-content">
                                        <span>EXPLORE THE SERVICES</span>
                                        <h2>Electrical and Plumbing Maintenance</h2>
                                        <p>Conducting routine inspections, repairs, and maintenance of electrical and plumbing systems to ensure proper functionality, safety, and compliance with regulations.</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Feature Four Single */}

                            {/* Start Feature Four Single */}
                            <div className="col-xl-4">
                                <div className="feature-four__single style2 text-center">
                                    <div className="feature-four__single-icon">
                                        <span className="icon-interior-design-2"></span>
                                    </div>
                                    <div className="feature-four__single-content">
                                        <span>EXPLORE THE SERVICES</span>
                                        <h2>Waterproofing Solutions</h2>
                                        <p>Applying effective waterproofing systems to prevent water intrusion and damage, protecting structures from leaks, moisture, and humidity-related issues.</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Feature Four Single */}

                            {/* Start Feature Four Single */}
                            <div className="col-xl-4">
                                <div className="feature-four__single style2 text-center">
                                    <div className="feature-four__single-icon">
                                        <span className="icon-interior-design-1"></span>
                                    </div>
                                    <div className="feature-four__single-content">
                                        <span>EXPLORE THE SERVICES</span>
                                        <h2>Façade Maintenance</h2>
                                        <p>Conducting façade inspections, cleaning, and maintenance to preserve the aesthetic appeal, structural integrity, and weatherproofing of building exteriors.</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Feature Four Single */}

                            {/* Start Feature Four Single */}
                            <div className="col-xl-4">
                                <div className="feature-four__single style2 text-center">
                                    <div className="feature-four__single-icon">
                                        <span className="icon-interior-design-2"></span>
                                    </div>
                                    <div className="feature-four__single-content">
                                        <span>EXPLORE THE SERVICES</span>
                                        <h2>Roofing Services</h2>
                                        <p>Repairing, replacing, or maintaining roofs to prevent leaks, water damage, and structural issues, ensuring the longevity and performance of the roofing system.</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Feature Four Single */}

                            {/* Start Feature Four Single */}
                            <div className="col-xl-4">
                                <div className="feature-four__single style2 text-center">
                                    <div className="feature-four__single-icon">
                                        <span className="icon-interior-design-1"></span>
                                    </div>
                                    <div className="feature-four__single-content">
                                        <span>EXPLORE THE SERVICES</span>
                                        <h2>Painting and Coating</h2>
                                        <p>Providing painting and coating services for interior and exterior surfaces, enhancing aesthetics, protecting against weathering, and extending the lifespan of the structure.</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Feature Four Single */}

                            {/* Start Feature Four Single */}
                            <div className="col-xl-4">
                                <div className="feature-four__single style2 text-center">
                                    <div className="feature-four__single-icon">
                                        <span className="icon-interior-design-2"></span>
                                    </div>
                                    <div className="feature-four__single-content">
                                        <span>EXPLORE THE SERVICES</span>
                                        <h2>Flooring Maintenance</h2>
                                        <p>Repairing, refinishing, and maintaining different types of flooring, including concrete, tiles, wood, and stone, to preserve their appearance, functionality, and durability.</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Feature Four Single */}

                            {/* Start Feature Four Single */}
                            <div className="col-xl-4">
                                <div className="feature-four__single style2 text-center">
                                    <div className="feature-four__single-icon">
                                        <span className="icon-interior-design-1"></span>
                                    </div>
                                    <div className="feature-four__single-content">
                                        <span>EXPLORE THE SERVICES</span>
                                        <h2>HVAC System Maintenance</h2>
                                        <p>Servicing and maintaining heating, ventilation, and air conditioning (HVAC) systems to optimize energy efficiency, indoor air quality, and overall system performance.</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Feature Four Single */}
                        </div>
                    </div>
                </section>
                {/* End Feature Four */}
            </>
        )
    }
}