import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <div className="main-menu text-center">
            <nav>
                <ul className="main-menu__list">
                    <li className="current"><Link to={process.env.PUBLIC_URL + `/`}>Home</Link></li>

                    <li><a href="#about">About</a></li>
                    
                    <li><a href="#services">Services</a></li>

                    <li><a href="#maintenance">Maintenance</a></li>

                    <li><a href="#gallery">Gallery</a></li>

                    <li><a href="#faq">Faq</a></li>

                    <li><a href="#goals">Goals</a></li>

                </ul>
            </nav>
        </div>
    )
}

export default Nav;


