import React from 'react';
import {Link} from 'react-router-dom';

export default class FooterOne extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <footer className="footer-one-sec">
                    <div className="footer-one__pattern" style={{backgroundImage: 'url('+publicUrl+'assets/images/pattern/footer-v1-pattern.png)'}}></div>
                    <div className="footer-one__top">
                        <div className="footer-one__top-img"
                            style={{backgroundImage: 'url('+publicUrl+'assets/images/resources/footer-v1-img3.png)'}}></div>
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="footer-one__top-inner">
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.1s">
                                                <div className="footer-widget__column footer-widget__about">
                                                    <div className="footer-widget__about-logo">
                                                        <Link to="/"><img src={publicUrl+"assets/images/resources/logo-1.png"} alt="Logo" style={{width:300}}/></Link>
                                                    </div>

                                                    <p className="footer-widget__about-text">Trust Ceylonarch to bring your vision to life, creating stunning and inspiring spaces that leave a lasting impression. Experience the artistry of architecture with Ceylonarch.</p>

                                                    <div className="footer-widget__about-social-link">
                                                        <ul>
                                                            <li>
                                                                <a href="https://www.facebook.com/ceylonarch">
                                                                    <span className="first icon-facebook-app-symbol"></span>
                                                                    <span className="second icon-facebook-app-symbol"></span>
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a href="mailto:info@ceylonarch.com">
                                                                    <span className="first icon-email-1"></span>
                                                                    <span className="second icon-email-1"></span>
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a href="https://wa.me/94778890737">
                                                                    <span className="first fab fa-whatsapp"></span>
                                                                    <span className="second fab fa-whatsapp"></span>
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a href="https://www.linkedin.com/company/ceylonarch/">
                                                                    <span className="first icon-linkedin"></span>
                                                                    <span className="second icon-linkedin"></span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-2 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.3s">
                                                <div className="footer-widget__column footer-widget__services">
                                                    <h2 className="footer-widget__title">Our Services</h2>
                                                    <ul className="footer-widget__services-list">
                                                        <li className="footer-widget__services-list-item"><Link to="#">Architectural Design</Link></li>
                                                        <li className="footer-widget__services-list-item"><Link to="#">Interior Designs</Link></li>
                                                        <li className="footer-widget__services-list-item"><Link to="#">Construction</Link></li>
                                                        <li className="footer-widget__services-list-item"><Link to="#">Landscape Design</Link></li>
                                                        <li className="footer-widget__services-list-item"><Link to="#">Gypsum Partitions</Link></li>
                                                        <li className="footer-widget__services-list-item"><Link to="#">2D & 3D Design</Link></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="col-xl-2 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.5s">
                                                <div className="footer-widget__column footer-widget__explore">
                                                    <h2 className="footer-widget__title">Explore</h2>
                                                    <ul className="footer-widget__explore-list">
                                                        <li className="footer-widget__explore-list-item"><Link to="#">Glass Partitions</Link></li>
                                                        <li className="footer-widget__explore-list-item"><Link to="#">Plastering</Link></li>
                                                        <li className="footer-widget__explore-list-item"><Link to="#">False Ceiling</Link></li>
                                                        <li className="footer-widget__explore-list-item"><Link to="#">Block Works</Link></li>
                                                        <li className="footer-widget__explore-list-item"><Link to="#">Tile Installation</Link></li>
                                                        <li className="footer-widget__explore-list-item"><Link to="#">Pool Design</Link></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="col-xl-4 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.5s">
                                                <div className="footer-widget__column footer-widget__explore">
                                                    <h2 className="footer-widget__title">Maintenance</h2>
                                                    <ul className="footer-widget__explore-list">
                                                        <li className="footer-widget__explore-list-item"><Link to="#">Civil Infrastructure Maintenance</Link></li>
                                                        <li className="footer-widget__explore-list-item"><Link to="#">Structural Repairs</Link></li>
                                                        <li className="footer-widget__explore-list-item"><Link to="#">Waterproofing Solutions</Link></li>
                                                        <li className="footer-widget__explore-list-item"><Link to="#">Façade Maintenance</Link></li>
                                                        <li className="footer-widget__explore-list-item"><Link to="#">Roofing Services</Link></li>
                                                        <li className="footer-widget__explore-list-item"><Link to="#">Electrical and Plumbing Maintenance</Link></li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="footer-one__bottom clearfix">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="footer-one__bottom-inner">
                                        <div className="footer-one__bottom-text">
                                            <p>Copyright &copy; 2021-2023 <strong>Ceylonarch</strong>. All Rights Reserved. Design By <strong><a href="https://www.amezcloud.com/">Amez Cloud Operatings</a></strong></p>
                                        </div>

                                        <div className="footer-one__bottom-list">
                                            <ul>
                                                <li><Link to="#">Terms & Condition </Link></li>
                                                <li><Link to="#"> Services</Link></li>
                                                <li><Link to="#">Careers</Link></li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        )
    }
}