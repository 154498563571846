import React from 'react';

export default class AboutTwo extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="about-two-sec">
                    <div className="container">
                        <div className="row">
                            {/* Start About Two Img */}
                            <div className="col-xl-6">
                                <div className="about-two__img">
                                    <div className="about-two__img1">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/about/about-v2-img1.jpg"} alt="" />
                                        </div>
                                    </div>
                                    <div className="about-two__img2">
                                        <img src={publicUrl+"assets/images/about/about-v2-img2.jpg"} alt="" />
                                    </div>
                                </div>
                            </div>
                            {/* End About Two Img */}

                            {/* Start About Two Content */}
                            <div className="col-xl-6">
                                <div className="about-two__content">
                                    <div className="sec-title">
                                        <div className="sec-title__tagline">
                                            <h6>VISION & MISSION </h6> <span className="right"></span>
                                        </div>
                                        <h2 className="sec-title__title">Vision</h2>
                                        <p className="sec-title__text">Our vision is to be a leading architectural and design firm that pioneers innovation, creativity, and sustainability, transforming spaces into exceptional environments that inspire and leave a lasting impact.</p>
                                    </div>
                                    <div className="sec-title">
                                        <h2 className="sec-title__title">Mission</h2>
                                        <p className="sec-title__text">Our mission is to provide unparalleled architectural and design solutions that elevate spaces, exceed client expectations, and positively impact the built environment. We are committed to delivering excellence through innovative designs, sustainable practices, and exceptional craftsmanship, while fostering collaborative partnerships and prioritizing client satisfaction at every step.</p>
                                    </div>


                                    <div className="about-two__content-text2">
                                        <div className="text-box">
                                            <p>Building Dreams, Creating Realities</p>
                                        </div>

                                        <div className="icon-box">
                                            <span className="icon-chart"></span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* End About Two Content */}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}