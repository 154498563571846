import React from 'react';

export default class ServiceOne extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="services-one-sec" id="services">
                    <div className="container">
                        <div className="sec-title text-center">
                            <div className="sec-title__tagline">
                                <span className="left"></span>
                                <h6>Our Services</h6> <span className="right"></span>
                            </div>
                            <h2 className="sec-title__title">Our Professional Services</h2>
                        </div>

                        <div className="row">
                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="services-one__single">
  

                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2>Architectural Design</h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p>Creating innovative and functional architectural designs that cater to the unique vision and requirements of clients, encompassing residential, commercial, institutional, and hospitality projects.</p>
                                        {/* 
                                        <div className="services-one__single-btn">
                                                <Link to={process.env.PUBLIC_URL + `/general-contracting`} className="thm-btn" data-text="Read More +">Read More +</Link>
                                            </div>  
                                        */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="services-one__single">


                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2>Interior Design</h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p>Crafting captivating and functional interior spaces that enhance aesthetics, functionality, and user experience, incorporating elements such as layout planning, material selection, lighting design, and furniture arrangement. </p>

                                            {/*
                                            <div className="services-one__single-btn">
                                                <Link to={process.env.PUBLIC_URL + `/general-contracting`} className="thm-btn" data-text="Read More +">Read More +</Link>
                                            </div>
                                            */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="services-one__single">

                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2>Construction</h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p>Providing end-to-end construction services, ensuring the seamless execution of projects from inception to completion, with a focus on quality craftsmanship, project management, and timely delivery. </p>

                                            {/* 
                                            <div className="services-one__single-btn">
                                                <Link to={process.env.PUBLIC_URL + `/general-contracting`} className="thm-btn" data-text="Read More +">Read More +</Link>
                                            </div>
                                            */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="services-one__single">

                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2>Sustainable Design</h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p>Integrating eco-friendly practices and technologies into architectural and design solutions, promoting energy efficiency, sustainable materials, green building practices, and environmentally conscious design principles.</p>

                                            {/*
                                            <div className="services-one__single-btn">
                                                <Link to={process.env.PUBLIC_URL + `/general-contracting`} className="thm-btn" data-text="Read More +">Read More +</Link>
                                            </div>
                                            */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}
                            
                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="services-one__single">
                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2>2D & 3D Planning and Design</h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p>Utilizing advanced software and visualization tools to create detailed 2D and 3D plans, allowing clients to visualize their projects and make informed decisions.</p>

                                            {/*
                                            <div className="services-one__single-btn">
                                                <Link to={process.env.PUBLIC_URL + `/general-contracting`} className="thm-btn" data-text="Read More +">Read More +</Link>
                                            </div>
                                            */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="services-one__single">

                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2>Architectural Visualizationction</h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p>Generating realistic renderings, walkthroughs, and virtual tours that bring architectural designs to life, enabling clients to experience and evaluate the final outcome before construction begins.</p>

                                            {/*
                                            <div className="services-one__single-btn">
                                                <Link to={process.env.PUBLIC_URL + `/general-contracting`} className="thm-btn" data-text="Read More +">Read More +</Link>
                                            </div>
                                            */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="services-one__single">

                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2>Woodworking Design</h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p>Offering custom woodwork design services, including furniture, cabinetry, and other wood-based elements that enhance the aesthetics and functionality of interior and exterior spaces.</p>

                                            {/*
                                            <div className="services-one__single-btn">
                                                <Link to={process.env.PUBLIC_URL + `/general-contracting`} className="thm-btn" data-text="Read More +">Read More +</Link>
                                            </div>
                                            */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="services-one__single">

                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2>Landscape Design</h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p>Creating beautiful and functional outdoor spaces, incorporating elements such as gardens, pathways, water features, and outdoor structures to enhance the overall ambience and harmony with the surroundings.</p>

                                            {/*
                                            <div className="services-one__single-btn">
                                                <Link to={process.env.PUBLIC_URL + `/general-contracting`} className="thm-btn" data-text="Read More +">Read More +</Link>
                                            </div>
                                            */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="services-one__single">

                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2>False Ceiling</h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p>Ceylonarch's false ceiling services aim to transform spaces by adding visual interest, improving acoustics, hiding wiring and ductwork, and creating a sophisticated and modern ambiance.</p>

                                            {/*
                                            <div className="services-one__single-btn">
                                                <Link to={process.env.PUBLIC_URL + `/general-contracting`} className="thm-btn" data-text="Read More +">Read More +</Link>
                                            </div>
                                            */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="services-one__single">

                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2>Custom Swimming Pool Design</h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p>Our pool designs encompass a wide range of styles, from sleek and modern to natural and tropical. We consider factors such as site conditions, available space, and desired features to develop a design that maximizes the pool's functionality while creating a captivating visual appeal.</p>

                                            {/*
                                            <div className="services-one__single-btn">
                                                <Link to={process.env.PUBLIC_URL + `/general-contracting`} className="thm-btn" data-text="Read More +">Read More +</Link>
                                            </div>
                                            */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="services-one__single">

                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2>Plastering</h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p>With our professional plastering services, we deliver smooth and flawless finishes to interior and exterior surfaces. Our experienced craftsmen meticulously apply plaster layers, ensuring an even texture and seamless appearance, enhancing the aesthetic appeal and durability of the walls.</p>

                                            {/*
                                            <div className="services-one__single-btn">
                                                <Link to={process.env.PUBLIC_URL + `/general-contracting`} className="thm-btn" data-text="Read More +">Read More +</Link>
                                            </div>
                                            */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="services-one__single">

                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2>Floor Tile Installation</h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p>Ceylonarch specializes in floor tile installation, transforming spaces with high-quality and visually striking tile designs. Our skilled team meticulously lays tiles, ensuring precise alignment, proper adhesion, and a durable flooring surface that enhances the overall aesthetic and functionality of the space.</p>

                                            {/*
                                            <div className="services-one__single-btn">
                                                <Link to={process.env.PUBLIC_URL + `/general-contracting`} className="thm-btn" data-text="Read More +">Read More +</Link>
                                            </div>
                                            */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}


                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="services-one__single">

                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2>Block Works</h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p>Ceylonarch excels in block works, encompassing the construction of walls, columns, and other structural elements using concrete blocks. Our skilled team ensures accurate and sturdy block installations, meeting structural requirements and providing a solid foundation for various construction projects.</p>

                                            {/*
                                            <div className="services-one__single-btn">
                                                <Link to={process.env.PUBLIC_URL + `/general-contracting`} className="thm-btn" data-text="Read More +">Read More +</Link>
                                            </div>
                                            */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="services-one__single">

                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2>Gypsum Partitions</h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p>Ceylonarch offers professional gypsum partition services, providing versatile and durable partition solutions that effectively divide interior spaces while maintaining a sleek and modern appearance. Our experts ensure precise installation, tailored to the specific layout and functional requirements of each project.</p>

                                            {/*
                                            <div className="services-one__single-btn">
                                                <Link to={process.env.PUBLIC_URL + `/general-contracting`} className="thm-btn" data-text="Read More +">Read More +</Link>
                                            </div>
                                            */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="services-one__single">

                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2>Glass Partitions</h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p>We specialize in glass partition installations, creating transparent and visually appealing divisions that promote natural light flow and an open atmosphere. Our glass partitions are crafted with high-quality materials and can be customized to suit various design preferences, offering both functionality and aesthetics.</p>

                                            {/*
                                            <div className="services-one__single-btn">
                                                <Link to={process.env.PUBLIC_URL + `/general-contracting`} className="thm-btn" data-text="Read More +">Read More +</Link>
                                            </div>
                                            */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}